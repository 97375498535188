import React from "react";
import parse from 'html-react-parser';

export default function Headline({ 
  h1, 
  subtitle
}) {
  return (
    <>
          <h1>
            {parse(`${h1}`)}
          </h1>
          <p className="text-lg lg:text-xl opacity-70 mb-8">
              {parse(`${subtitle}`)}
          </p>
    </>
  );
}
